.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.nav li>button, .nav li>button:hover {
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.scroll-container {
  position: absolute;
  top: 2vh;
  right: 2vh;
  width: 20vw;
  height: auto;
  overflow: hidden;
  white-space: nowrap;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 5px;
  font-size: 18px;
  text-align: right;
}

.scroll-title {
  font-weight: bold;
  margin-bottom: 5px; /* Space between title and scrolling text */
  color: white;
}

.scroll-text {
  display: inline-block;
  animation: scroll 15s linear infinite;
  white-space: nowrap; /* Ensures text does not wrap */
}

.scroll-text::after {
  content: attr(data-text); /* Duplicate the text content */
  padding-left: 10px; /* Space between the duplicated texts */
}