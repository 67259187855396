.modal {
    z-index: 2001 !important;
    backdrop-filter: blur(15px);

    .modal-content {
        border-width: 0;
        border-radius: 1.5rem;
        background-color: rgba(255, 255, 255, 0); 
    }

    .modal-header {
        border-color: #aaaaaa;
    }

    .modal-title {
        font-family: "Amatic SC", serif;
        font-size: 2.25rem;
        line-height: 2.5rem;
        font-weight: 1000;
        color: #000;
        stroke: white;
        stroke-width: 2;
        text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    }

    p {
        color: white;
    }

    h3 {
        font-weight: 600;
        text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
        margin-bottom: 0;
    }
}

.modal-dialog {
    width: 85vw;
    max-width: 85vw !important;
}

.amatic-title {
    font-size: 1.35rem;
    font-family: "Amatic SC", serif;
    font-weight: 600;
    color: #000;
    stroke: white;
    stroke-width: 2;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}
.amatic-title-reverse {
    font-size: 1.35rem;
    font-family: "Amatic SC", serif;
    font-weight: 600;
    
    stroke: #000;
    stroke-width: 2;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

.bg-transparent-1 {
    background-color: rgba(255, 255, 255, 0.3); 
}

.bg-transparent-2 {
    background-color: rgba(255, 255, 255, 0.125); 
}

@keyframes scroll {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  
  .scroll-container {
    position: absolute;
    top: 2vh;
    right: 2vh;
    width: 20vw;
    height: auto;
    overflow: hidden;
    white-space: nowrap;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 10px;
    border-radius: 5px;
    font-size: 18px;
    text-align: right;
  }
  
  .scroll-title {
    font-weight: bold;
    margin-bottom: 5px; /* Space between title and scrolling text */
    color: black;
    stroke: #000;
    stroke-width: 2;
  }
  
  .scroll-text {
    display: inline-block;
    animation: scroll 15s linear infinite;
    white-space: nowrap; /* Ensures text does not wrap */
  }
  
  .scroll-text::after {
    content: attr(data-text); /* Duplicate the text content */
    padding-left: 10px; /* Space between the duplicated texts */
  }